import { ru } from 'date-fns/esm/locale'

export const RU = {
  'AssetStock': {
    'type': 'Тип:',
    'market': 'Биржа:',
    'currency': 'Валюта:',
    'country': 'Страна:',
    'aboutcompany': 'О компании',
    'address1': 'Адрес:',
    'city': 'Город:',
    'website': 'Сайт:',
    'industry': 'Отрасль:',
    'sector': 'Сектор:',
    'zip': 'Почтовый ндекс:',
    'updatedAt': 'Обновлено:',
    'marketStatus': 'Биржа',
    'open': 'открыта',
    'close': 'закрыта',
  },
  'CreatePortfolio': {
    'title': 'Создание Портфлеля',
    'name': 'Название',
    'save': 'Создать',
  },
  'CreateTransaction': {
    'modalText': 'Сначала, нужно создать портфель!',
    'modalButton': 'Создать',
    'title': 'Создание Транзакции',
    'ticker': 'Тикер',
    'date': 'Дата',
    'time': 'Время',
    'timePlaceholder': 'HH:MM',
    'category': 'Выбор Категории',
    'stock': 'Акции',
    'currency': 'Валюта',
    'other': 'Прочее',
    'type': 'Выбор Типа',
    'buy': 'Покупка',
    'sell': 'Продажа',
    'dividend': 'Дивиденды',
    'tax': 'Налог',
    'portfolio': 'Выбор Портфеля',
    'count': 'Количество',
    'price': 'Цена за Единицу',
    'commission': 'Процент Комиссии',
    'percent': 'Процент?',
    'save': 'Создать',
    'addCashTransaction': 'Добавить транзакцию с валютой',
    'add': 'Добавить',
  },
  'Dashboard': {
    'categoryRatio': 'Категории',
    'tickerRatio': 'Активы',
    'stock': 'Акции',
    'etf': 'ETF',
    'currency': 'Валюты',
    'cryptocurrency': 'Крипта',
    'other': 'Прочее',
  },
  'Home': {
    'portfoliosRatio': 'Доли Портфелей',
  },
  'DeletePortfolio': {
    'title': 'Удаление Портфеля',
    'delete': 'Удалить',
    'cancel': 'Отмена',
  },
  'DeleteTransaction': {
    'title': 'Удаление Транзакции',
    'delete': 'Удалить',
    'cancel': 'Отмена',
  },
  'Feedback': {
    'title': 'Какой тип отзыва вы хотите нам передеать?',
    'report': 'Сообщить о проблеме',
    'share': 'Поделиться идеей',
    'give': 'Сделать комплимент...',
    'tell': 'Раскажите нам о вашей проблеме',
    'placeholder': 'Напишите своё сообщение для администрации здесь.',
    'send': 'Отправить',
  },
  'MobileFooter': {
    'feedback': 'Отзыв',
    'about': 'О нас',
    'copyright': 'Copyright 2025',
    'company': 'Reagle Investment',
  },
  'Sidebar': {
    'home': 'Главная',
    'dashboard': 'Обзор',
    'portfolios': 'Портфели',
    'transactions': 'Транзакции',
    'settings': 'Настройки',
    'feedback': 'Отзыв',
    'logout': 'Выйти',
  },
  'Login': {
    'signIn': 'Авторизация',
    'access': 'Введите email и пароль для доступа к Reagle',
    'email': 'Email',
    'emailPlaceholder': 'Введите ваш Email',
    'password': 'Пароль',
    'forgot': 'Забыли пароль?',
    'passwordPlaceholder': 'Введите пароль',
    'new': 'Впервые у нас?',
    'create': 'Создать аккаунт',
    'copy': '2025 Reagle. All Rights Reserved.',
  },
  'NotFound': {
    'title': 'Oops! А что мы тут делаем?',
    'massage': 'Так, здесь ничего нет, так что возвращайся назад, а то сейчас как отправлю на другую страницу!',
  },
  'Portfolio': {
    'categoryRatio': 'Категории',
    'tickerRatio': 'Активы',
    'stock': 'Акции',
    'etf': 'ETF',
    'currency': 'Валюты',
    'cryptocurrency': 'Крипта',
    'other': 'Прочее',
  },
  'Registration': {
    'title': 'Регистрация',
    'make': 'Сделайте свои инвестиции продуктивными с Reagle.',
    'email': 'Email',
    'emailPlaceholder': 'Введите свой Email',
    'password': 'Пароль',
    'passwordPlaceholder': 'Введите свой пароль',
    'currencies': 'Валюты',
    'language': 'Язык',
    'already': 'Уже есть аккаунт?',
    'signIn': 'Можете авторизоваться',
    'copy': ' 2025 Reagle. All Rights Reserved.',
  },
  'ResetPassword': {
    'modalTitleSuccess': 'Спасибо, что отправили форму.',
    'modalTitleSuccessText': 'Вы  можете проверить ваш почтовый ящик.',
    'modalTitleError': 'Ошибка',
    'modalTitleTextError': 'Похоже, вы ввели некорректный адрес или данный андрес не найден.',
    'title': 'Сменить пароль',
    'titleText': 'Если вы забыли пароль, мы отправим вам на почту инстукцию по его смене.',
    'email': 'Почта',
    'emailPlaceholder': 'Введи вашу почту',
    'send': 'Send Reset Link',
    'return': 'Return to login',
    'copy': ' 2025 Reagle. All Rights Reserved.',
  },
  'ResetPasswordFinish': {
    'modalTitleError': 'Ошибка',
    'modalTitleTextError': 'Похоже, срок вашей ссылки истёк, попробуйте выполнить сброс занаво',
    'title': 'Смена пароля',
    'titleText': 'Введите новый пароль, который отличается от старого',
    'passwordPlaceholder': 'Введите новый пароль',
    'send': 'Сменить',
    'copy': '2025 Reagle. All Rights Reserved.',
    'reAuthenticationTextSuccess': 'Мы отправили вам письмо для активации аккаунта на вашу почту',
    'reAuthenticationTextError': 'Что-то пошло не так. Пожалуйста, повторите попытку позже',
  },
  'Search': {
    'searchPlaceholder': 'Начните вводить тикер или название',
    'title': 'Поиск',
    'stock': 'Акции',
    'etf': 'ETF',
    'currency': 'Валюты',
    'cryptocurrency': 'Крипта',
    'ticker': 'Тикер',
    'name': 'Название',
    'country': 'Страна',
    'price': 'Цена',
  },
  'SettingsLayout': {
    'general': 'Основные настройки',
    'security': 'Безопасность',
  },
  'PersonalSettings': {
    'title': 'Основные настройки',
    'basic': 'Базовые настройки функций, которые вы используете на платформе Reagle.',
    'basics': 'Базовые настройки',
    'currencies': 'Валюты',
    'language': 'Язык',
    'updateP': 'Обновить профиль',
    'main': 'Главная валюта',
    'additional': 'Дополнительная валюта',
    'update': 'Сохранить',
    'cancel': 'Отмена',
  },
  'SecuritySettings': {
    'title': 'Настройки Безопасности',
    'these': 'Данный раздел настроек поможет держать ваш аккаунт в безопаности.',
    'password': 'Пароль',
    'email': 'Почта',
    'address': 'Aдрес электронной почты для работы с Reagle.',
    'change': 'Изменить',
    'set': 'Установите уникальный пароль для безопасности вашего аккаунта.',
    'changePassword': 'Изменить Пароль',
    'changeEmail': 'Изменить почту',
    'placeholderPassword': 'Введите новый  пароль здесь...',
    'placeholderEmail': 'Введите новую  почту здесь...',
    'authentication': 'Аутентификация',
    'after': 'После активации вы можете использовать нашу платформу без ограничений.',
    'activate': 'Активировать',
    'modalActivateTittle': 'Отправить повторное подтверждение E-Mail Адреса?',
    'modalActivateSend': 'Отправить',
    'modalActivateCencel': 'Отменить',
  },
  'Shared': {
    'Assets': {
      'assets': 'Активы',
      'all': 'All',
      'stock': 'Акции',
      'etf': 'ETF',
      'currency': 'Валюты',
      'cryptocurrency': 'Крипта',
      'other': 'Прочее',
      'type': 'Тип',
      'ticker': 'Тикер',
      'portfolioPercent': 'Процент от портфеля',
      'count': 'Количество',
      'cost': 'Цена за единицу',
      'purchaseCost': 'Цена покупки',
      'sum': 'Суммарна стоимость',
      'profit': 'Прибыль',
    },
    'CapitalFlow': {
      'title': 'Динамика Капитала',
      'total': 'All',
    },
    'PortfolioList': {
      'modalDeleteTitle': 'Вы уверены?',
      'modalDeleteText': 'Это действие невозможно отменить!',
      'modalDeleteConfirm': 'Да, удалить!',
      'modalDeleteCancel': 'Отмена!',
      'modalUpdateEdit': 'Обновить Портфель',
      'modalUpdate': 'Сохранить',
      'title': 'Портфели',
      'create': 'Создать',
      'name': 'Название',
      'baseCost': 'Базовая стоимость',
      'cost': 'Стоимость',
      'profit': 'Прибыль',
      'actions': 'Действия',
      'trend': 'Динамика',
      'edit': 'Обновить',
      'remove': 'Удалить',
    },
    'TotalValue': {
      'title': 'Капитал',
      'overview': 'Обзор',
      'currentProfit': 'Текущая Прибыль',
      'current': 'Процент Прибыли',
      'allTimeProfit': 'All Time P&L',
      'allTime': 'All Time P&L Процент',
      'baseCostAndPercent': 'Базовая Стоимость | ATP / БС',
      'commissionAndDividends': 'Комиссии и Налоги | Дивиденды',
    },
    'TransactionList': {
      'modalDeleteTitle': 'Вы уверены?',
      'modalDeleteText': 'Отменить это действие невозможно!',
      'modalDeleteConfirm': 'Удалить!',
      'modalDeleteCancel': 'Отмена!',
      'title': 'Транзакции',
      'createNew': 'Создать',
      'all': 'All',
      'buy': 'Покупка',
      'sell': 'Продажа',
      'dividend': 'Дивиденды',
      'tax': 'Налог',
      'stock': '',
      'currency': '',
      'other': '',
      'type': 'Тип',
      'asset': 'Актив',
      'date': 'Дата',
      'price': 'Цена за Единицу',
      'count': 'Количество',
      'commission': 'Комиссия',
      'cost': 'Стоимость',
      'editTransaction': 'Обновить',
      'removeTransaction': 'Удалить',
    },
  },
  'UpdatePortfolio': {
    'title': 'Обновить Портфель',
    'portfolioName': 'Название',
    'update': 'Сохранить',
  },
  'UpdateTransaction': {
    'title': 'Обновить Транзакцию',
    'ticker': 'Тикер',
    'date': 'Дата',
    'time': 'Время',
    'timePlaceholder': 'HH:MM',
    'choose': 'Выбор Категории',
    'stock': 'Акции',
    'etf': 'ETF',
    'currency': 'Валюта',
    'cryptocurrency': 'Крипта',
    'other': 'Прочее',
    'choosetype': 'Выбор Типа',
    'buy': 'Покупка',
    'sell': 'Продажа',
    'choosePortfolio': 'Выбор Портфеля',
    'count': 'Количество',
    'price': 'Цена',
    'commission': 'Комиссия в числовом эквиваленте',
    'percent': 'Процент?',
    'update': 'Сохранить',
    'cashTransactionText': 'Если вы использовали автоматическую операцию с валютой при создании транзакции (добавление/снятие), то по необходимости, вам нужно редактировать данную транзакцию вручную.',
    datePickerLocale: ru,
    datePickerFormat: 'd MMMM, yyyy h:mm',
  },
  'Verify': {
    'modalTitleSuccess': 'Успешно',
    'modalTitleError': 'Ошибка',
    'html': 'Сейчас, отправим вас на Reagle',
    'text': 'Что-то пошло не так, попробуйте еще раз',
  },
  'Watchlist': {
    'title': 'Watchlist',
    'addAsset': 'Добавить актив',
    'type': 'Категория',
    'ticker': 'Тикер',
    'select': 'Выбрать',
    'portfolioAssetsTitle': 'Активы портфеля',
    'modalCreateTitle': 'Добавить элемент в Watchlist',
    'modalCreateConfirm': 'Добавить',
    'modalCreateCancel': 'Отмена',
    'modalDeleteTitle': 'Удалить элемент из Watchlist',
    'modalDeleteConfirm': 'Удалить',
    'modalDeleteCancel': 'Отмена',
  },
}
